<template>
  <v-card
    @click="$emit('member_clicked', member)"
    class="mx-auto"
    width="100%"
    outlined
  >
    <v-card-text>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            {{ member.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ member.expertise }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar class="rounded-circle" tile size="80" color="grey">
          <v-img :src="member.img"></v-img>
        </v-list-item-avatar>
      </v-list-item>
    </v-card-text>
    <v-card-text>
      <v-btn text color="accent">Meer lezen</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    member: Object,
  },
};
</script>

<style>
</style>