<template>
  <v-container class="my-16">
    <h2 class="accent--text">Team</h2>
    <v-divider class="py-3"></v-divider>
    <v-row width="100%">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        v-for="employee in employees"
        :key="employee.id"
      >
        <TeamMember
          @member_clicked="
            (value) => {
              dialog = !dialog;
              selectedEmployee = value;
            }
          "
          :member="employee"
        />
      </v-col>
    </v-row>
    <TeamMemberDialog
      v-if="dialog"
      :member="selectedEmployee"
      :dialog="dialog"
      @popupClosed="dialog = !dialog"
    />
  </v-container>
</template>

<script>
import TeamMember from "@/components/TeamMember";
import TeamMemberDialog from "@/components/TeamMemberDialog";
export default {
  data() {
    return {
      selectedEmployee: {},
      dialog: false,
      employees: [
        {
          id: 1,
          name: "Dierenarts Winius",
          img: "https://www.sterkliniekamsterdam.nl/images/dokter%20winius1.jpg",
          expertise: "Dierenarts",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 2,
          name: "Dierenarts Hoekstra",
          img: "https://www.sterkliniekamsterdam.nl/images/Drs%20Hoekstra.jpg",
          expertise: "Dierenarts",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 3,
          name: "Dierenarts Verhoeven",
          img: "https://www.sterkliniekamsterdam.nl/images/iconVet.jpg",
          expertise: "Dierenarts",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 4,
          name: "Dierenarts Lafuente",
          img: "https://www.sterkliniekamsterdam.nl/images/iconVet.jpg",
          expertise: "Dierenarts",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 5,
          name: "Eva de Boer-Veerman",
          img: "https://www.sterkliniekamsterdam.nl/images/eva%20de%20boer-veerman1.jpg",
          expertise: "Paraveterinair",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 6,
          name: "Chantal van Hoekelen",
          img: "https://www.sterkliniekamsterdam.nl/images/Chantal-van-Hoekelen3.jpg",
          expertise: "Paraveterinair",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 7,
          name: "Kim Broertjes",
          img: "https://www.sterkliniekamsterdam.nl/images/Kim-Broertjes.jpg",
          expertise: "Paraveterinair",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
      ],
    };
  },
  components: {
    TeamMember,
    TeamMemberDialog,
  },
  methods: {
    setupDialog(value) {
      this.selectedEmployee = value;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>