<template>
  <div class="text-center">
    <v-dialog v-model="toggleDialog" persistent width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ member.name }}
        </v-card-title>

        <v-card-text class="text-justify mt-5">
          {{ member.description }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDiag"> Terug </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleDialog: this.dialog,
    };
  },
  props: {
    dialog: Boolean,
    member: Object,
  },
  methods: {
    closeDiag() {
      this.toggleDialog = false;
      this.$emit("popupClosed");
    },
  },
};
</script>

<style>
</style>