<template>
  <v-container>
    <SmallText />
    <TeamList />
  </v-container>
</template>

<script>
import SmallText from "@/components/SmallText";
import TeamList from "@/components/TeamList";
export default {
  components: {
    SmallText,
    TeamList,
  },
  mounted() {
    scrollDown();
  },
  methods: {
    scrollDown() {
      window.scrollBy(100, 0);
    },
  },
};
</script>

<style>
</style>