<template>
  <v-row>
    <v-col cols="12" xs="12" class="d-flex align-center justify-center">
      <v-card :width="width">
        <v-card-title class="accent white--text">{{ text.title }}</v-card-title>
        <v-divider></v-divider>

        <v-card-actions>
          <v-card-text class="text-justify">
            {{ text.desc }}
          </v-card-text>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1200;
        case "sm":
          return 1000;
        case "md":
          return 800;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  data() {
    return {
      text: {
        title: "Titel van het tekstje",
        desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque maiores officia assumenda, iste voluptas tenetur beatae quasi? Possimus, distinctio. Est aspernatur dolore, reiciendis perspiciatis ad voluptatum fugit odit architecto vel, doloribus numquam. Asperiores magnam dignissimos tempora distinctio veritatis veniam nisi.",
      },
    };
  },
};
</script>

<style>
</style>